import React from "react"
import { Layout } from "@components/layout"
import { wrapper } from "./styles/rodo.module.scss"

const Cookies = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout {...page}>
      <div className={wrapper}>
        <h1>Polityka cookies</h1>
        <iframe src="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/polityka-cookies.pdf"></iframe>
      </div>
    </Layout>
  )
}

export default Cookies
